import PERMESSI from '@/models/permessoEnum'

export default [
  {
    header: 'Livello 2',
    action: [PERMESSI.LIVELLO2_AGGIUNGI, PERMESSI.LIVELLO2_VISUALIZZA],
  },
  {
    key: 'lv-aggiungi',
    title: 'Carica',
    icon: 'PlusIcon',
    route: 'lv-aggiungi',
    action: PERMESSI.LIVELLO2_AGGIUNGI,
  },
  {
    key: 'lv-elenco',
    title: 'Elenco',
    icon: 'ListIcon',
    route: 'lv-elenco',
    action: PERMESSI.LIVELLO2_VISUALIZZA,
  },
]
