<template>
  <div>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <span
          style="margin-right: 5px"
        >{{ userInfo !== null ? userInfo.profile.email : "" }}</span>
        <b-avatar
          size="40"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        />
      </template>

      <!--      <b-dropdown-item-->
      <!--        link-class="d-flex align-items-center"-->
      <!--        @click="routeSettings()"-->
      <!--      >-->
      <!--        <feather-icon-->
      <!--          size="16"-->
      <!--          icon="SettingsIcon"-->
      <!--          class="mr-50"-->
      <!--        />-->
      <!--        <span>Impostazioni</span>-->
      <!--      </b-dropdown-item>-->

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="routeCambiaPassword()"
      >
        <feather-icon
          size="16"
          icon="KeyIcon"
          class="mr-50"
        />
        <span>Cambia Password</span>
      </b-dropdown-item>

      <!-- <b-dropdown-divider /> -->

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout()"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>

    </b-nav-item-dropdown>
  </div>
</template>

<script>

export default {
  computed: {
    userInfo() {
      if (this.$store.state.auth.AppActiveUser != null) return this.$store.state.auth.AppActiveUser
      return null
    },
  },
  methods: {
    routeCambiaPassword() {
      if (this.$route.path !== '/identity/cambia-password') {
        this.$router.push({ name: 'identity-cambia-password' })
      }
    },
    routeSettings() {
      if (this.$route.path !== '/identity/impostazioni') {
        this.$router.push({ name: 'identity-impostazioni' })
      }
    },
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style scoped lang="scss">
  div::v-deep {
    ul {
      &.dropdown-menu {
        &.dropdown-menu-right {
          min-width: 200px;
        }
      }
    }
  }
</style>
