<template>
  <b-row
    v-if="($route.meta.breadcrumb || $route.meta.pageTitle)"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            v-if="$route.meta.back || $route.meta.buttons"
            class="content-header-title float-left pr-1 mb-0 mr-1"
          >
            <b-button
              v-if="$route.meta.back"
              variant="primary"
              size="sm"
              :to="to($route.meta.back)"
              title="Torna Indietro"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>

            <b-button
              v-for="(item, index) in $route.meta.buttons"
              :key="item.key"
              :to="to(item)"
              :title="item.title"
              :class="!$route.meta.back && index === 0 ? '' : 'ml-1'"
              variant="primary"
              size="sm"
            >
              <feather-icon :icon="item.icon" />
            </b-button>
          </h2>
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{
              $route.meta.pageTitle != null
                ? $route.meta.pageTitle
                : text($route.meta.breadcrumb[$route.meta.breadcrumb.length - 1])
            }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="to(item)"
              >
                {{ text(item) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      params: null,
    }
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'breadcrumb/ADD_PARAMS') {
        this.params = this.$store.getters['breadcrumb/getParams'](this.$route.name)
      }
    })
  },
  methods: {
    text(item) {
      let { prefix } = item
      let { text } = item

      const hasPrefix = prefix !== null && prefix !== undefined
      if (!hasPrefix) prefix = ''

      if (this.params != null && text.toString().startsWith(':')) {
        const field = text.substr(1, text.length)
        text = this.params[field]
      }

      return `${prefix} ${text}`
    },
    to(item) {
      if (!(item.to !== undefined && item.to !== null && item.to.includes(':'))) return item.to
      if (this.params === undefined || this.params === null) return item.to

      const splitted = item.to.split('/')
      let toReturn = item.to
      Object.values(splitted).forEach(key => {
        if (key.startsWith(':')) {
          const field = key.substr(1, item.length)
          const value = this.params[field]
          toReturn = toReturn.replace(key, value)
        }
      })

      return toReturn
    },
  },
}
</script>
