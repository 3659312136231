import PERMESSI from '@/models/permessoEnum'

export default [
  {
    header: 'Gruppi',
    action: 'auth',
  },
  {
    key: 'gruppi-aggiungi',
    title: 'Aggiungi',
    icon: 'PlusIcon',
    route: 'gruppi-aggiungi',
    action: PERMESSI.GRUPPI_AGGIUNGI,
  },
  {
    key: 'gruppi-elenco',
    title: 'Elenco',
    icon: 'ListIcon',
    route: 'gruppi-elenco',
    action: 'auth',
  },
]
