<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="appSite"
        target="_blank"
      >{{ appName }}</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
  </p>
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  data() {
    return {
      appName: $themeConfig.app.appName,
      appSite: $themeConfig.app.appPublicSite,
    }
  },
}
</script>
