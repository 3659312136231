import PERMESSI from '@/models/permessoEnum'

export default [
  {
    header: 'Anagrafiche',
    action: [PERMESSI.OPERE_AGGIUNGI, PERMESSI.OPERE_VISUALIZZA],
  },
  {
    key: 'opere-aggiungi',
    title: 'Aggiungi',
    icon: 'PlusIcon',
    route: 'opere-aggiungi',
    action: PERMESSI.OPERE_AGGIUNGI,
  },
  {
    key: 'opere-elenco',
    title: 'Elenco',
    icon: 'ListIcon',
    route: 'opere-elenco',
    action: PERMESSI.OPERE_VISUALIZZA,
  },
]
