import PERMESSI from '@/models/permessoEnum'
import RUOLI from '@/models/ruoloEnum'

export default [
  {
    header: 'Ispezioni',
    action: [PERMESSI.ISPEZIONI_VISUALIZZA, PERMESSI.ISPEZIONI_AGGIUNGI],
    resource: RUOLI.CONCESSIONARIO,
  },
  {
    key: 'ispezioni-aggiungi',
    title: 'Aggiungi',
    icon: 'PlusIcon',
    route: 'ispezioni-aggiungi',
    action: PERMESSI.ISPEZIONI_AGGIUNGI,
  },
  {
    key: 'ispezioni-elenco',
    title: 'Elenco',
    icon: 'ListIcon',
    children: [
      {
        key: 'ispezioni-elenco-compilare',
        title: 'Da Compilare',
        route: { name: 'ispezioni-elenco-compilare', params: { id: 1 } },
        icon: 'BookOpenIcon',
        action: PERMESSI.ISPEZIONI_VISUALIZZA,
      },
      {
        key: 'ispezioni-elenco-compilate',
        title: 'Compilate',
        route: { name: 'ispezioni-elenco-compilate', params: { id: 2 } },
        icon: 'Edit3Icon',
        action: PERMESSI.ISPEZIONI_VISUALIZZA,
      },
      {
        key: 'ispezioni-elenco-validate',
        title: 'Validate',
        route: { name: 'ispezioni-elenco-validate', params: { id: 3 } },
        icon: 'CheckIcon',
        action: PERMESSI.ISPEZIONI_VISUALIZZA,
      },
      {
        key: 'ispezioni-elenco-approvate',
        title: 'Approvate',
        route: { name: 'ispezioni-elenco-approvate', params: { id: 4 } },
        icon: 'CheckCircleIcon',
        action: PERMESSI.ISPEZIONI_VISUALIZZA,
        resource: RUOLI.CONCESSIONARIO,
      },
      {
        key: 'ispezioni-elenco',
        title: 'Tutte',
        route: 'ispezioni-elenco',
        icon: 'ListIcon',
        action: PERMESSI.ISPEZIONI_VISUALIZZA,
      },
    ],
  },
]
