import PERMESSI from '@/models/permessoEnum'

export default [
  {
    header: 'Utenti',
    action: [PERMESSI.UTENTI_AGGIUNGI, PERMESSI.UTENTI_VISUALIZZA],
  },
  {
    key: 'utenti-aggiungi',
    title: 'Aggiungi',
    icon: 'PlusIcon',
    route: 'utenti-aggiungi',
    action: PERMESSI.UTENTI_AGGIUNGI,
  },
  {
    key: 'utenti-elenco',
    title: 'Elenco',
    icon: 'ListIcon',
    route: 'utenti-elenco',
    action: PERMESSI.UTENTI_VISUALIZZA,
  },
]
